import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"

const SvgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  div {
    margin: 1em 0em;
    width: 1200px;
  }
  img {
    height: auto;
    width: 940px;
  }
`

const Svg = () => {
  return (
    <>
      <SvgWrapper>
        <p>export folder (1-3)</p>
        <StaticImage
          src="../images/test-images/export/1.svg"
          placeholder="none"
          quality={100}
          alt="export 1"
        />
        <StaticImage
          src="../images/test-images/export/3.svg"
          placeholder="none"
          quality={100}
          alt="export 2"
        />
        <StaticImage
          src="../images/test-images/export/3.svg"
          placeholder="none"
          quality={100}
          alt="export 3"
        />
        <p>
          save as folder (converted to outlines, 1.1-converted to outlines,
          svg-basic-1.2, svg tiny-1.2)
        </p>
        <StaticImage
          src="../images/test-images/save-as/converted-to-outlines.svg"
          placeholder="none"
          quality={100}
          alt="converted to outlines"
        />
        <StaticImage
          src="../images/test-images/save-as/1.1-converted-to-outline.svg"
          placeholder="none"
          quality={100}
          alt="1.1-converted-to-outline"
        />
        <StaticImage
          src="../images/test-images/save-as/svg-basic-1.1.svg"
          placeholder="none"
          quality={100}
          alt="svg-basic-1.1"
        />
        <StaticImage
          src="../images/test-images/save-as/svg-tiny-1.2.svg"
          placeholder="none"
          quality={100}
          alt="svg-tiny-1.2"
        />
        <p>sketch export</p>
        <StaticImage
          src="../images/test-images/sketch-svg.svg"
          placeholder="none"
          quality={100}
          alt="sketch export"
        />
        <p>figma export</p>
        <StaticImage
          src="../images/test-images/figma-export.svg"
          placeholder="none"
          quality={100}
          alt="sketch export"
        />
      </SvgWrapper>
    </>
  )
}

export default Svg
